import { connect } from "react-redux";

import { getShouldRenderMobileComponent } from "@data/selectors/BrowserSelectors";

import DesktopContactPage from "@desktop/ContactPage";
import MobileContactPage from "@mobile/ContactPage";

const mapStateToProps = (state: any) => ({
  shouldRenderMobileComponent: getShouldRenderMobileComponent(state),
});

type PropsType = ReturnType<typeof mapStateToProps>;

const ContactPageSwitch = ({ shouldRenderMobileComponent }: PropsType) => {
  return shouldRenderMobileComponent ? (
    <MobileContactPage />
  ) : (
    <DesktopContactPage />
  );
};

export default connect(mapStateToProps)(ContactPageSwitch);
