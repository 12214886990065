import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Linkedin from "@assets/icons/LinkedIn";
import {
  PersonCardWrapper,
  PersonCardDetail,
  PersonCardLinkedInWrapper,
  PersonCardTitle,
} from "@styles/desktop/Card";

type PropsType = {
  name: string;
  title?: string;
  email?: string;
  phoneNumber?: string;
  profileUrl?: string;
  isMobile?: boolean;
  image: any;
};

const PersonCard = ({
  name,
  title,
  email,
  phoneNumber,
  profileUrl,
  isMobile = false,
  image,
}: PropsType) => {
  const subTitleFontSize = isMobile ? "14px" : "18px";
  return (
    <PersonCardWrapper>
      {image}
      <PersonCardDetail>
        <PersonCardTitle isMobile={isMobile}>{name}</PersonCardTitle>
        <div style={{ fontSize: subTitleFontSize }}>{title}</div>
        {!isMobile && (
          <>
            <div style={{ margin: "20px 0 10px" }}>{email}</div>
            {/* <div>{phoneNumber}</div> */}
          </>
        )}
      </PersonCardDetail>
      <PersonCardLinkedInWrapper isMobile={isMobile}>
        <a
          href={profileUrl}
          style={{ color: "inherit", textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Linkedin />
        </a>
      </PersonCardLinkedInWrapper>
    </PersonCardWrapper>
  );
};

export default PersonCard;
