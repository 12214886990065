import { Row, Col, Divider as AntDivider } from "antd";
import {
  InfoHeader,
  InfoBody,
  ContentWrapper,
  InfoLink,
  BottomSectionHeader,
} from "@styles/desktop/HomePage";

import { LogoImg } from "@styles/desktop/Navbar";

const BottomSection = () => {
  return (
    <ContentWrapper>
      <BottomSectionHeader>
        <LogoImg style={{ width: "120px" }} />
      </BottomSectionHeader>
      <Row style={{ marginTop: "10px" }}>
        <Col span={16}>
          <InfoHeader>MEDNERVA - Away From Bias</InfoHeader>
          <InfoBody>MEDNERVA.AI</InfoBody>
          <InfoBody>
            University Avenue and, Oxford St, Berkeley, CA 94720
          </InfoBody>
        </Col>
        <Col span={4}>
          <div>
            <InfoLink href="/about">ABOUT</InfoLink>
          </div>
          <div>
            <InfoLink>TECHNOLOGY</InfoLink>
          </div>
          <div>
            <InfoLink>CONTACT</InfoLink>
          </div>
        </Col>
      </Row>
      <AntDivider style={{ borderTop: "1px solid #ffffff26" }} />
      <Row style={{ marginTop: "2px" }}>
        <Col span={8}>
          <InfoBody>Copywrite @2024 MEDNERVA All Rights Reserved</InfoBody>
        </Col>
      </Row>
    </ContentWrapper>
  );
};

export default BottomSection;
