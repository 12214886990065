import { useNavigate } from "react-router-dom";
import { ABOUT_PAGE, CONTACT_PAGE } from "../../Routes";
import { motion, useScroll, useSpring } from "framer-motion";

/* Components */
import { Form, Modal } from "antd";
import Button, { BUTTON_TYPE } from "@common/Button";
import Navbar from "@desktop/Navbar";
import BottomContent from "@desktop/BottomSection";
import { connect } from "react-redux";
import SaveCompanyInfoService from "@services/SaveCompanyInfoService";
import ContentCard from "@desktop/ContentCard";
import ImageCard from "@common/ImageCard";
import LongContentCard from "@desktop/LongContentCard";
import ArrowButtonText from "@desktop/ArrowTextButton";

/* Styles */
import {
  Wrapper,
  Content,
  TitleContainer,
  Slogan,
  SloganHeader,
  Divider,
  CardContainer,
  TextWrapper,
  WhiteText,
  DetailSection,
  ContentWrapper,
  DetailSectionHeader,
  ButtonWrapper,
  DetailSectionSubHeader,
  IntroSection,
  IntroSectionBody,
  IntroSectionHeader,
  IntroSectionPersonCard,
  IntroSectionPersonCardDetail,
  IntroSectionPersonCardLinkedInWrapper,
  IntroSectionPersonImage,
  IntroSectionSubHeader,
  IntroSectionPersonPhotoWrapper,
  IntroTextContainer,
  IntroSectionContainer,
} from "@styles/desktop/HomePage";
import { PerceptionImg, IndividualImg } from "@styles/desktop/Card";

import Linkedin from "@assets/icons/LinkedIn";

import { PerceptionImageBody, IndividualBiasImageBody } from "@data/Constants";

const mapDispatchToProps = (dispatch: any) => ({
  saveInfo: (surveyForm: any) => SaveCompanyInfoService(surveyForm),
});

type PropsType = ReturnType<typeof mapDispatchToProps>;

const HomePage = ({ saveInfo }: PropsType) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const onSubmit = (values: any) => {
    saveInfo(values);
    Modal.success({
      content: "Work in progress",
    });
  };

  const { scrollYProgress } = useScroll();

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
  });

  return (
    <>
      <Navbar />
      <Wrapper>
        <Content>
          <TitleContainer>
            <SloganHeader>
              Revolutionizing Medical Diagnostics with AI-Powered Bias Reduction
            </SloganHeader>
            <Slogan>
              Enhance diagnostic accuracy and reduce healthcare costs
            </Slogan>
            <Button
              overrideStyle={{ maxWidth: "300px", margin: "20px auto" }}
              onClick={() => {
                navigate(ABOUT_PAGE);
              }}
            >
              Explore our tech
            </Button>
          </TitleContainer>
        </Content>
        <motion.div
          animate={Number(scrollYProgress) > 0.1 ? "visible" : "hidden"}
          style={{ scaleX }}
        >
          <Divider />
        </motion.div>

        <TextWrapper>
          <WhiteText>
            Medical diagnostics are critical to patient care.
            <span style={{ color: "#01bcc8" }}>
              But ensuring accuracy can be a challenge.
            </span>
          </WhiteText>
        </TextWrapper>
        <CardContainer>
          <ContentCard
            title={"90"}
            titleSuffix={"B"}
            text={
              "$90 billion is lost annually due to diagnostic errors in the U.S. healthcare system."
            }
            footer={"Johns Hopkins University Study (2023)"}
          />
          <ContentCard
            title={"75"}
            titleSuffix={"%"}
            text={
              "75% of errors are caused by human biases, leading to misdiagnosis and delayed treatment."
            }
            footer={"National Academy of Medicine (2023)"}
          />
          <ContentCard
            title={"21"}
            titleSuffix={"B"}
            text={
              "$21 billion annual loss in radiology and dermatology by 2030 due to these biases."
            }
            footer={"Radiological Society of North America (2023)"}
          />
        </CardContainer>
        <TextWrapper>
          <WhiteText>
            A groundbreaking solution that is{" "}
            <span style={{ color: "#01bcc8" }}>
              reshaping diagnostic accuracy.
            </span>
          </WhiteText>
        </TextWrapper>
        <DetailSection>
          <ContentWrapper>
            <DetailSectionHeader>MEDNERVA</DetailSectionHeader>
            <DetailSectionSubHeader>
              Delivering reliable and scalable bias reduction in medical
              diagnostics
            </DetailSectionSubHeader>
            <CardContainer>
              <ImageCard
                prefix={"01"}
                title={"Perceptual and cognitive biases"}
                body={PerceptionImageBody}
                image={<PerceptionImg />}
              />
              <ImageCard
                prefix={"02"}
                title={"Intrinsic individual biases"}
                body={IndividualBiasImageBody}
                image={<IndividualImg />}
              />
            </CardContainer>
            <ButtonWrapper>
              <Button
                overrideStyle={{
                  maxWidth: "200px",
                  margin: "20px auto",
                  backgroundColor: "black",
                  color: "white",
                }}
                onClick={() => {
                  navigate(ABOUT_PAGE);
                }}
              >
                Explore our tech
              </Button>
            </ButtonWrapper>
          </ContentWrapper>
        </DetailSection>
        <ContentWrapper>
          <div style={{ textAlign: "center", margin: "160px auto" }}>
            <WhiteText>How will MEDNERVA be available?</WhiteText>
          </div>
          <CardContainer>
            <LongContentCard
              title="API to existing diagnosis solution"
              text="
              Integrating MedNerva’s API into existing diagnostic solutions provides an easy way for hospitals and imaging centers to reduce bias. It's even more accessible for remote diagnosis providers"
              boldText="Easy to integrate, reduce bias in minutes"
              footer="Want to know more"
              onArrowClick={() => navigate(ABOUT_PAGE)}
            />
            <LongContentCard
              title="Remote Diagnosis Platform"
              text="Introducing the next generation of remote diagnosis platforms, empowering clinicians with accurate, unbiased insights no matter where they are in the world. Find the best doctor for each patient based on their diagnosis history"
              boldText="Precision diagnostics, anywhere in the world"
              footer="Join the test waitlist"
              onArrowClick={() => navigate(CONTACT_PAGE)}
            />
          </CardContainer>
        </ContentWrapper>
        <IntroSection>
          <ContentWrapper>
            <IntroSectionContainer>
              <IntroTextContainer>
                <IntroSectionHeader>How it all started</IntroSectionHeader>
                <IntroSectionSubHeader>
                  Frontier Research at UC Berkeley’s Perception Lab
                </IntroSectionSubHeader>
                <IntroSectionBody>
                  Mednerva’s journey began with Frontier Research at UC
                  Berkeley’s Perception Lab Tackling neuroscience x healthcare
                </IntroSectionBody>
                <ArrowButtonText
                  text="Discover our story"
                  onArrowClick={() => navigate(ABOUT_PAGE)}
                />
              </IntroTextContainer>
              <IntroSectionPersonPhotoWrapper>
                <IntroSectionPersonCard>
                  <IntroSectionPersonImage />
                  <IntroSectionPersonCardDetail>
                    <h4>Dave Whitney</h4>
                    <div>Director of Cognitive Science UC Berkeley</div>
                  </IntroSectionPersonCardDetail>
                  <IntroSectionPersonCardLinkedInWrapper>
                    <Linkedin />
                  </IntroSectionPersonCardLinkedInWrapper>
                </IntroSectionPersonCard>
              </IntroSectionPersonPhotoWrapper>
            </IntroSectionContainer>
          </ContentWrapper>
        </IntroSection>

        <TextWrapper>
          <WhiteText>
            Our Vision is to{" "}
            <span style={{ color: "#01bcc8" }}>
              revolutionize medical diagnostics by eliminating cognitive bias,
            </span>
          </WhiteText>
        </TextWrapper>
        <BottomContent />
      </Wrapper>
    </>
  );
};

export default connect(null, mapDispatchToProps)(HomePage);
