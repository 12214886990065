import { useNavigate } from "react-router-dom";

/* Styles */
import {
  Wrapper,
  ContentWrapper,
  StatementWrapper,
} from "@styles/mobile/HomePage";
import {
  AboutHeaderSection,
  AboutHeaderBreadcrumb,
  BreadcrumbLine,
  BreadcrumbText,
  IntroSectionHeader,
  IntroSectionSubHeader,
} from "@styles/mobile/AboutPage";
import {
  VideoWrapper,
  DetailSection,
  EssentialHeader,
  EssentialSubheader,
  EssentialLearnMoreWrapper,
  EssentialLearnMoreHeader,
  EssentialLearnMoreBody,
} from "@styles/mobile/TechPage";
import { Divider } from "antd";

/* Components */
import IconCard, { ICON_TYPE } from "@common/IconCard";
import IconCardSmall from "@common/IconCardSmall";
import RedKey from "@assets/icons/RedKey";
import GreenDiamond from "@assets/icons/GreenDiamond";
import InclineAI from "@assets/icons/inclineAI";
import InclineImage from "@assets/icons/inclineImage";
import Button from "@common/Button";
import Footer from "@mobile/Footer";
import Navbar from "@mobile/Navbar";
import { CONTACT_PAGE } from "../../Routes";

const TechPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <Wrapper>
        <AboutHeaderSection>
          <ContentWrapper>
            <AboutHeaderBreadcrumb>
              <BreadcrumbLine />
              <BreadcrumbText>Technology</BreadcrumbText>
            </AboutHeaderBreadcrumb>
          </ContentWrapper>
        </AboutHeaderSection>
        <ContentWrapper>
          <div style={{ marginBottom: "6vh", textAlign: "left" }}>
            <StatementWrapper>
              Committed to solving critical healthcare challenges through
              <span style={{ color: "#01bcc8" }}>
                {"  "}innovative AI technology.
              </span>
            </StatementWrapper>
          </div>
        </ContentWrapper>
        {/* <ContentWrapper>
          <VideoWrapper>
            <video
              width="100%"
              style={{ borderRadius: "16px" }}
              src="https://cdn.prod.website-files.com/666947d3f0c542911e8e494b%2F669a4446e1068f8879be106c_mobile-bg-video-transcode.mp4"
              autoPlay
            ></video>
          </VideoWrapper>
        </ContentWrapper> */}
        <DetailSection>
          <ContentWrapper>
            <IntroSectionHeader>
              Empowering Medical Diagnostics
            </IntroSectionHeader>
            <IntroSectionSubHeader>
              MEDNERVA is a scalable AI-driven solution that reduces diagnostic
              bias in medical imaging. Our technology analyzes clinician
              interpretations, image sequences, and diagnostic patterns to
              ensure unbiased, accurate results for each patient. By integrating
              seamlessly into existing medical imaging systems, MEDNERVA
              enhances diagnostic precision, providing healthcare providers with
              reliable insights.
            </IntroSectionSubHeader>
            <Divider />
            <IntroSectionHeader style={{ fontSize: "20px" }}>
              Our functions
            </IntroSectionHeader>
            <IntroSectionSubHeader style={{ fontSize: "15px" }}>
              Embedding MEDNERVA's AI into the diagnostic workflow to analyze
              image sequences and detect bias during clinician interpretation
            </IntroSectionSubHeader>
            <IconCard
              prefix="01"
              title="Embed"
              iconType={ICON_TYPE.SQUARE}
              body="Embedding MEDNERVA’s AI into the diagnostic workflow to analyze image sequences and detect bias during clinician interpretation."
              isMobile
            />
            <IconCard
              prefix="02"
              title="Analyze"
              iconType={ICON_TYPE.SCAN}
              body="The advanced MEDNERVA engine evaluates each image sequence and clinician decision, ensuring every diagnosis is unbiased and consistent."
              isMobile
            />
            <IconCard
              prefix="03"
              title="Alert on demand"
              body="MEDNERVA can mitigate the sequential bias without bothering the clinicians, while it could alert clinicians with feedback when it's desired."
              iconType={ICON_TYPE.WARN}
              isMobile
            />
          </ContentWrapper>
        </DetailSection>
        <ContentWrapper>
          <EssentialHeader>MEDNERVA essentials</EssentialHeader>
          <EssentialSubheader>
            Our AI-powered technology is designed for scalability, robustness,
            and improving medical diagnostics by mitigating clinician bias. We
            build with these core principles to enhance diagnostic accuracy.
          </EssentialSubheader>
          <IconCardSmall
            title="Robustness"
            body="MEDNERVA ensures resilient performance across various medical imaging platforms, maintaining diagnostic integrity. Our system is built to withstand evolving diagnostic environments with consistent accuracy."
            image={<InclineImage />}
            isMobile
          />
          <IconCardSmall
            title="AI-Powered"
            body="As diagnostic challenges evolve, so does MEDNERVA. Our AI uses iterative learning processes to continually improve diagnostic outcomes, applying insights from real-world data to ensure future reliability."
            image={<InclineAI />}
            isMobile
          />
          <IconCardSmall
            image={<GreenDiamond />}
            title="Scalable"
            body="MEDNERVA integrates seamlessly with healthcare systems globally. Whether in large hospitals or remote diagnostics, our solution scales to meet the needs of diverse medical environments.
"
            isMobile
          />
          <IconCardSmall
            image={<RedKey />}
            title="Embed"
            body="MEDNERVA’s AI significantly reduces diagnostic bias, improving patient outcomes. It delivers consistent and accurate results without the risk of subjective interpretation errors, providing high confidence in medical decisions."
            isMobile
          />
          <EssentialLearnMoreWrapper>
            <EssentialLearnMoreHeader>
              Integrating our technology
            </EssentialLearnMoreHeader>
            <EssentialLearnMoreBody>
              MEDNERVA technology seamlessly integrates into existing diagnostic
              systems, helping clinicians and healthcare providers reduce bias
              and improve diagnostic outcomes from day one.
            </EssentialLearnMoreBody>
            <Button
              overrideStyle={{ fontSize: "14px" }}
              onClick={() => {
                navigate(CONTACT_PAGE);
              }}
            >
              I want to hear more
            </Button>
          </EssentialLearnMoreWrapper>
        </ContentWrapper>
        <Footer />
      </Wrapper>
    </>
  );
};

export default TechPage;
