type PropsType = {
  overrideStyle?: any;
};

const OrangeWarn = ({ overrideStyle }: PropsType) => {
  return (
    <svg
      width="320"
      height="190"
      viewBox="0 0 320 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.749951"
        width="161.796"
        height="161.796"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 159.748 2.375)"
        stroke="#00CAAC"
        strokeWidth="1.5"
      />
      <path
        opacity="0.4"
        d="M291.327 98.744L302.641 105.275L161.22 186.918L19.7984 105.275L31.1121 98.744"
        stroke="#00CAAC"
        strokeWidth="1.5"
      />
      <rect
        width="111.041"
        height="59.6029"
        transform="matrix(-0.866044 -0.499967 0.866044 -0.499967 174.656 130.18)"
        fill="url(#paint0_linear_2477_491)"
      />
      <rect
        width="111.041"
        height="19.5955"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 147.785 34.6562)"
        fill="url(#paint1_linear_2477_491)"
      />
      <line
        y1="-1.5"
        x2="111.041"
        y2="-1.5"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 130.107 44.8594)"
        stroke="#0DDEBB"
        strokeWidth="3"
      />
      <path
        d="M137.886 28.9415L147.785 23.2266L157.685 28.9415"
        stroke="#00CAAC"
        strokeWidth="2"
      />
      <path
        d="M181.019 137.933L171.119 143.648L161.22 137.933"
        stroke="#00CAAC"
        strokeWidth="2"
      />
      <path
        d="M253.851 84.4569L263.75 90.1719L253.851 95.8869"
        stroke="#00CAAC"
        strokeWidth="2"
      />
      <path
        d="M65.0538 82.4181L55.1543 76.7031L65.0538 70.9881"
        stroke="#00CAAC"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2477_491"
          x1="55.5206"
          y1="0"
          x2="55.5206"
          y2="59.6029"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0DDEBB" stopOpacity="0" />
          <stop offset="1" stopColor="#0DDEBB" stopOpacity="0.45" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2477_491"
          x1="55.5206"
          y1="0"
          x2="55.5206"
          y2="19.5955"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0DDEBB" stopOpacity="0" />
          <stop offset="1" stopColor="#0DDEBB" stopOpacity="0.45" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default OrangeWarn;
