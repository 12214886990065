export const BREAK_POINTS = {
  extraSmall: 400,
  small: 600,
  medium: 768,
  large: 1060,
};
export const PerceptionImageBody = [
  "Identify various human factor biases",
  "Provide bias reduction services",
  "Explore new patterns of human factor biases",
];
export const IndividualBiasImageBody = [
  "Provide doctor profiling services",
  "Analyze medical cases with AI",
  "Bias-aware intelligent case distribution service",
];
