import { Form, Input as rawInput, Modal } from "antd";
import styled from "styled-components";

/* Styles */
import { ContentWrapper } from "@styles/mobile/HomePage";
import {
  GetInTouchTextContentWrapper,
  GetInTouchHeader,
  GetInTouchSubHeader,
} from "@styles/common/ContactSectionMobile";

/* Components */
import Button, { BUTTON_TYPE } from "@common/Button";

const Input = styled(rawInput)`
  color: white;
  &::placeholder {
    color: white;
    opacity: 0.7;
  }
`;

const { TextArea } = Input;

export const StyledTextArea = styled(TextArea)`
  color: white;
  background: #ffffff0d;
  border: 1px solid #ffffff1a;

  ::placeholder {
    color: white;
    opacity: 0.7;
  }
`;

type PropsType = { hideBackground?: boolean };

const ContactForm = ({ hideBackground = false }: PropsType) => {
  const [form] = Form.useForm();
  const onSubmit = async (values: any) => {
    Modal.success({
      content: "Work in progress",
    });
    // await SaveCompanyInfoService(values);
  };
  return (
    <>
      <ContentWrapper>
        <GetInTouchTextContentWrapper>
          <GetInTouchHeader>Get in touch</GetInTouchHeader>
          <GetInTouchSubHeader>
            We're excited to connect with you. Feel free to drop us a message
            anytime.
          </GetInTouchSubHeader>
          <Form form={form} name="collect_info" onFinish={onSubmit}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "cannot leave empty" }]}
            >
              <Input
                placeholder="Full Name*"
                style={{
                  color: "white",
                  height: "60px",
                  background: "#ffffff0d",
                  border: "1px solid #ffffff1a",
                }}
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Cannot leave empty" }]}
            >
              <Input
                placeholder="Email*"
                style={{
                  height: "60px",
                  background: "#ffffff0d",
                  border: "1px solid #ffffff1a",
                }}
              />
            </Form.Item>
            <Form.Item
              name="message"
              rules={[{ required: true, message: "Cannot leave empty" }]}
            >
              <StyledTextArea
                placeholder="Message*"
                style={{ height: "100px" }}
              />
            </Form.Item>
            <Button
              variant={BUTTON_TYPE.GHOST}
              overrideStyle={{
                fontSize: "14px",
                paddingTop: "4px",
                paddingBottom: "4px",
              }}
            >
              Send Message
            </Button>
          </Form>
        </GetInTouchTextContentWrapper>
      </ContentWrapper>
    </>
  );
};

export default ContactForm;
