type PropsType = {
  overrideStyle?: any;
};

const GreenDiamond = ({ overrideStyle }: PropsType) => {
  return (
    <svg
      width="115"
      height="80"
      viewBox="0 0 115 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.749951"
        width="64.5743"
        height="64.5743"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 57.8731 8.375)"
        stroke="url(#paint0_linear_2479_495)"
        stroke-width="1.5"
        stroke-dasharray="8 8"
      />
      <rect
        y="0.749951"
        width="41.2581"
        height="41.2581"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 37.6798 20.0312)"
        stroke="url(#paint1_linear_2479_495)"
        stroke-width="1.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2479_495"
          x1="-4.12937"
          y1="-3.87115"
          x2="69.8658"
          y2="-3.88081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D8FF86" stop-opacity="0.25" />
          <stop offset="1" stop-color="#D8FF86" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2479_495"
          x1="-2.6722"
          y1="-2.5051"
          x2="45.2116"
          y2="-2.51135"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D8FF86" />
          <stop offset="1" stop-color="#D8FF86" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default GreenDiamond;
