import styled from "styled-components";
import doctor from "@assets/images/doctor.png";
import DavidImg from "@assets/images/david.png";

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background: #111224;
  padding-bottom: 2vh;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  background: url(${doctor}) lightgray 50% / cover no-repeat;
  min-height: 1600px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, #111224 0%, rgba(182, 182, 183, 0) 40%);
    z-index: 1; /* Ensures the overlay is above the content background */
    pointer-events: none; /* Allows clicks to pass through the overlay if needed */
  }
`;

export const TitleContainer = styled.div`
  max-width: 40vw;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin: 40vh auto 10vh;
`;

export const BackgroundImg = styled.img.attrs({ src: doctor })`
  position: relative;
  width: 100%;
  top: 0px;
  z-index: -1;
`;

export const Slogan = styled.div`
  font-family: "Heiti TC";
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.5px; /* 150% */
  letter-spacing: 1px;
  margin-top: 20px;
  text-shadow: 0 0 12px #392f2f;
`;

export const SloganHeader = styled.div`
  font-family: "Heiti TC";
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 120% */
  letter-spacing: 1px;
`;

export const SloganBody = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #fde29d;
`;

export const CardWrapper = styled.div`
  display: flex;
  width: 80vw;
  margin-left: 10vw;
  z-index: 2;
`;

export const Divider = styled.div`
  width: 90%;
  margin: 40px 10vw;
  border: 1px solid #ffffff26;
`;

export const BottomSectionContainer = styled.div`
  margin: 0;
`;

export const BottomSectionHeader = styled.div`
  font-size: 25px;
  line-height: 1.5em;
  color: white;
  letter-spacing: 0.1em;
  text-align: center;
`;

export const InfoHeader = styled.div`
  color: white;
  text-align: left;
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 500;
`;

export const InfoBody = styled.div`
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 0.5;
  margin-top: 8px;
`;

export const InfoLink = styled.a`
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 0.5;
  margin-top: 8px;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  width: 90%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
`;

export const CounterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const CounterText = styled.div`
  margin-left: 200px;
  color: white;
`;

export const TextWrapper = styled.div`
  width: 90%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`;

export const WhiteText = styled.p`
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2em;
  word-spacing: 2px;
  letter-spacing: 2px;
`;

export const BlueText = styled.div`
  color: #01bcc8;
  font-family: "DM Sans", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2em;
  word-spacing: 2px;
  letter-spacing: 2px;
`;

export const DetailSection = styled.div`
  display: flex;
  height: 940px;
  padding: 60px 260px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  background: linear-gradient(275deg, #e3f8ff 0%, #9fc7d3 100%);
`;

export const ContentWrapper = styled.div`
  width: 90%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
`;

export const DetailSectionHeader = styled.div`
  text-align: left;
  color: #111224;
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  width: 80%;
`;

export const DetailSectionSubHeader = styled.div`
  color: #111224;
  font-family: "DM Sans", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.5px; /* 150% */
  margin-bottom: 40px;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 40px;
`;

export const IntroSection = styled.div`
  background: linear-gradient(
    66deg,
    rgba(54, 171, 164, 0.15) 0%,
    rgba(78, 117, 200, 0.5) 100%
  );
  display: flex;
  height: 940px;
  padding: 60px 260px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-top: 140px;
`;
export const IntroSectionContainer = styled.div`
  display: flex;
  font-weight: 300;
`;

export const IntroTextContainer = styled.div`
  width: 50%;
  margin-right: 80px;
`;
export const IntroSectionHeader = styled.h2`
  color: #fff;
  font-size: 32px;
  font-style: normal;
  line-height: 1.4em;
`;

export const IntroSectionSubHeader = styled.div`
  color: #fff;
  font-size: 22px;
  font-style: normal;
  line-height: 1.4em;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 1px;
`;

export const IntroSectionBody = styled.div`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: 1.5em;
  letter-spacing: 1px;
  opacity: 0.5;
  margin-bottom: 20px;
`;

export const IntroSectionPersonPhotoWrapper = styled.div`
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  display: flex;
`;

export const IntroSectionPersonCard = styled.div`
  max-width: 450px;
  position: relative;
  color: white;
`;

export const IntroSectionPersonImage = styled.img.attrs({ src: DavidImg })`
  border-radius: 16px;
  width: 100%;
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
`;

export const IntroSectionPersonCardDetail = styled.div`
  z-index: 10;
  margin-right: 50px;
  position: absolute;
  inset: auto auto 25px 25px;
`;

export const IntroSectionPersonCardLinkedInWrapper = styled.div`
  z-index: 10;
  background-color: #fff0;
  border: 1px solid #ffffff80;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 12px;
  transition: all 0.15s;
  display: flex;
  position: absolute;
  inset: auto 25px 25px auto;
`;
