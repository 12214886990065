import Navbar from "@desktop/Navbar";
import { useNavigate } from "react-router-dom";

/* Styles */
import {
  Wrapper,
  ContentWrapper,
  WhiteText,
  IntroSectionBody,
  IntroSectionHeader,
  IntroSectionSubHeader,
  IntroSectionPersonPhotoWrapper,
  IntroTextContainer,
  IntroSectionContainer,
} from "@styles/desktop/HomePage";
import {
  AboutHeaderSection,
  AboutHeaderBreadcrumb,
  BreadcrumbLine,
  BreadcrumbText,
  PeopleSection,
  PeopleCardWrapper,
  PeopleSectionHeader,
  GridLayout,
  Header,
  Subheader,
  SkillWrapper,
  SkillImage,
  DotWrapper,
  DotPlacer,
  ExplanationText,
  FingerImage,
} from "@styles/desktop/AboutPage";

/* Components */
import BottomContent from "@desktop/BottomSection";
import PersonCard from "@common/PersonCard";
import ArrowButtonText from "@desktop/ArrowTextButton";
import Dot from "@assets/icons/Dot";
import {
  DavidImage,
  PeterImage,
  AkshaImage,
  VeithImage,
  MulinImage,
} from "@styles/desktop/Card";
import { TECH_PAGE } from "../../Routes";

const AboutPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <Wrapper>
        <AboutHeaderSection>
          <ContentWrapper>
            <AboutHeaderBreadcrumb>
              <BreadcrumbLine />
              <BreadcrumbText>About</BreadcrumbText>
            </AboutHeaderBreadcrumb>
          </ContentWrapper>
        </AboutHeaderSection>
        <ContentWrapper>
          <div style={{ marginBottom: "2vh" }} />
          <WhiteText>
            Our Vision is to
            <br />
            <span style={{ color: "#01bcc8" }}>
              revolutionize medical diagnostics by eliminating cognitive and
              perceptual biases.
            </span>
          </WhiteText>
          <div style={{ marginBottom: "8vh" }} />
          <IntroSectionContainer>
            <IntroTextContainer>
              <IntroSectionHeader>Inventing MEDNERVA</IntroSectionHeader>
              <IntroSectionSubHeader>
                Understanding the how bias is first identified in medical system
                with cutting-edge research
              </IntroSectionSubHeader>
              <IntroSectionBody>
                “To solve critical problems in healthcare, we must address their
                root cause: diagnostic bias. Our mission is to provide tools
                that reduce human error and save lives,” says Whitney,
                Co-founder. This principle from the MedNerva leadership team
                drives our vision of transforming healthcare through innovation.
              </IntroSectionBody>
              <IntroSectionBody>
                <strong>How MEDNERVA came about</strong>
                <br />
                The story began at UC Berkeley's Perception Lab, where
                cutting-edge research on human cognition and perception revealed
                the impact of bias in diagnostic imaging. The team, led by some
                of the world’s foremost experts in neuroscience and healthcare,
                realized the magnitude of this issue. With decades of clinical
                and research expertise, MedNerva’s technology leverages AI to
                reduce cognitive and perceptual biases, providing clinicians
                with reliable diagnostic support. This groundbreaking solution
                emerged from an unwavering commitment to improving patient care
                and healthcare outcomes globally
              </IntroSectionBody>
              <ArrowButtonText
                text="Explore the technology"
                onArrowClick={() => navigate(TECH_PAGE)}
              />
            </IntroTextContainer>
            <IntroSectionPersonPhotoWrapper>
              <FingerImage />
            </IntroSectionPersonPhotoWrapper>
          </IntroSectionContainer>
        </ContentWrapper>
        <PeopleSection>
          <ContentWrapper>
            <PeopleSectionHeader>Meet the teams</PeopleSectionHeader>
            <PeopleCardWrapper>
              <PersonCard
                name="Akshat Gupta"
                title="CEO"
                email="akshat.g@berkeley.edu"
                profileUrl="https://www.linkedin.com/in/iamakshat/"
                image={<AkshaImage />}
              />
              <PersonCard
                name="Veith Weilnhammer"
                title="Board-certified psychiatrist, psychotherapist, neuroscientist"
                profileUrl="https://veithweilnhammer.github.io/"
                email="veith.weilnhammer@berkeley.edu"
                image={<VeithImage />}
              />
            </PeopleCardWrapper>
            <PeopleCardWrapper>
              <PersonCard
                name="Zhihang Ren"
                title="Research scientist"
                profileUrl="https://www.linkedin.com/in/zhihang-ren-831a15146/"
                email="peter.zhren@berkeley.edu"
                image={<PeterImage />}
              />
              <PersonCard
                name="Mulin Yang"
                title="Senior Software Engineer"
                profileUrl="https://www.linkedin.com/in/mulin-yang-bb5521112/"
                email="operataion@mednerva.ai"
                image={<MulinImage />}
              />
              <PersonCard
                name="David Whitney"
                title="Director of Cognitive Science UC Berkeley"
                email="dwhitney@berkeley.edu "
                profileUrl="https://vcresearch.berkeley.edu/faculty/david-whitney"
                image={<DavidImage />}
              />
            </PeopleCardWrapper>
          </ContentWrapper>
        </PeopleSection>
        <ContentWrapper>
          <GridLayout>
            <Header>Our Skills</Header>
            <Subheader>
              At MedNerva, we've built a powerhouse team of specialists to
              tackle diagnostic bias in healthcare head-on. Our team is uniquely
              positioned to bring world-class solutions to the medical
              diagnostics industry.
            </Subheader>
          </GridLayout>
          <SkillWrapper>
            <SkillImage />
            <DotWrapper>
              {/*UX & UI*/}
              <DotPlacer top="79.5%" left="64%">
                <Dot color="#01BCC8" />
                <ExplanationText top="79.5%" left="64%">
                  Ensuring the platform is intuitive and accessible,
                  streamlining workflows for clinicians and improving adoption
                  rates
                </ExplanationText>
              </DotPlacer>
              {/*AI Engineer*/}
              <DotPlacer top="27.5%" left="25%">
                <Dot color="#01BCC8" />
                <ExplanationText top="27.5%" left="25%">
                  Building advanced machine learning models that enhance
                  diagnostic accuracy by mitigating clinician bias
                </ExplanationText>
              </DotPlacer>
              {/*Medical Expert*/}
              <DotPlacer top="28%" left="60.5%">
                <Dot color="#01BCC8" />
                <ExplanationText top="28%" left="60.5%">
                  Collaborating closely with clinicians to ensure our AI models
                  are clinically relevant and effective
                </ExplanationText>
              </DotPlacer>
              {/*Cognitive Neuroscientist*/}
              <DotPlacer top="52%" left="12.5%">
                <Dot color="#01BCC8" />
                <ExplanationText top="52%" left="12.5%">
                  Pioneering research into how human perception impacts medical
                  diagnostics, helping shape MedNerva’s technology to reduce
                  cognitive biases in decision-making
                </ExplanationText>
              </DotPlacer>
              {/*Data Scientists*/}
              <DotPlacer top="72%" left="31%">
                <Dot color="#01BCC8" />
                <ExplanationText top="72%" left="31%">
                  Our scientists are skilled at designing and refining
                  algorithms that process and analyze vast amounts of medical
                  imaging data
                </ExplanationText>
              </DotPlacer>
              {/*Full Stack*/}
              <DotPlacer top="60%" left="51.5%">
                <Dot color="#01BCC8" />
                <ExplanationText top="60%" left="51.5%">
                  Our full stack engineers are skilled at architecting and
                  maintaining MedNerva’s tech stack for seamless integration
                  with healthcare systems
                </ExplanationText>
              </DotPlacer>
              {/*Product Manager*/}
              <DotPlacer top="53.5%" left="70%">
                <Dot color="#01BCC8" />
                <ExplanationText top="53.5%" left="70%">
                  Guiding product strategy and development, ensuring MedNerva’s
                  solutions meet the needs of healthcare professionals
                </ExplanationText>
              </DotPlacer>
            </DotWrapper>
          </SkillWrapper>
        </ContentWrapper>
        <BottomContent />
      </Wrapper>
    </>
  );
};

export default AboutPage;
