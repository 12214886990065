type PropsType = {
  overrideStyle?: any;
  color?: string;
};

const Dot = ({ overrideStyle, color = "#111224" }: PropsType) => {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      style={{ ...overrideStyle }}
    >
      <g>
        <path
          d="M11 22.97C17.0751 22.97 22 18.0451 22 11.97C22 5.89484 17.0751 0.969971 11 0.969971C4.92487 0.969971 0 5.89484 0 11.97C0 18.0451 4.92487 22.97 11 22.97Z"
          fill={color}
          fill-opacity="0.15"
        />
        <path
          d="M11 17.97C14.3137 17.97 17 15.2837 17 11.97C17 8.65626 14.3137 5.96997 11 5.96997C7.68629 5.96997 5 8.65626 5 11.97C5 15.2837 7.68629 17.97 11 17.97Z"
          fill={color}
          fill-opacity="0.6"
        />
      </g>
    </svg>
  );
};
export default Dot;
