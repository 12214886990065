type PropsType = {
  overrideStyle?: any;
};

const inclineAI = ({ overrideStyle }: PropsType) => {
  return (
    <svg
      width="115"
      height="80"
      viewBox="0 0 115 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2479_494)">
        <path
          d="M69.4898 36.4552C71.7226 37.7442 75.3428 37.7442 77.5756 36.4552C79.8085 35.1661 79.8085 33.0762 77.5756 31.7871C75.3428 30.4981 71.7226 30.4981 69.4898 31.7871C67.2569 33.0762 67.2569 35.1661 69.4898 36.4552Z"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
        <path
          d="M68.7535 52.2052C70.9863 53.4942 74.6065 53.4942 76.8393 52.2052C79.0722 50.9161 79.0722 48.8262 76.8393 47.5371C74.6065 46.2481 70.9863 46.2481 68.7535 47.5371C66.5206 48.8262 66.5206 50.9161 68.7535 52.2052Z"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
        <path
          d="M42.3033 52.1505C44.5361 53.4395 48.1563 53.4395 50.3891 52.1505C52.622 50.8614 52.622 48.7715 50.3891 47.4825C48.1563 46.1934 44.5361 46.1934 42.3033 47.4825C40.0704 48.7715 40.0704 50.8614 42.3033 52.1505Z"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
        <path
          d="M34.7881 40.963C37.021 42.252 40.6411 42.252 42.874 40.963C45.1068 39.6739 45.1068 37.584 42.874 36.295C40.6411 35.0059 37.021 35.0059 34.7881 36.295C32.5553 37.584 32.5553 39.6739 34.7881 40.963Z"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
        <path
          d="M50.5967 31.838C52.8296 33.127 56.4497 33.127 58.6826 31.838C60.9154 30.5489 60.9154 28.459 58.6826 27.17C56.4497 25.8809 52.8296 25.8809 50.5967 27.17C48.3639 28.459 48.3639 30.5489 50.5967 31.838Z"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
        <path
          d="M57.8694 43.1546C59.6725 44.1955 62.596 44.1955 64.3992 43.1546C66.2023 42.1136 66.2023 40.4258 64.3992 39.3849C62.596 38.3439 59.6725 38.3439 57.8694 39.3849C56.0662 40.4258 56.0662 42.1136 57.8694 43.1546Z"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
        <path
          d="M60.1824 30.3202L68.5878 32.4698"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
        <path
          d="M73.4771 37.4219L72.7477 46.5686"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
        <path
          d="M67.1296 50.2905L52.0624 49.8454"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
        <path
          d="M40.5259 41.7812L43.6519 46.7059"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
        <path
          d="M44.0959 37.3395L67.9483 34.8213"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
        <path
          d="M56.7793 32.5625L59.7455 38.7264"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
        <path
          d="M64.0376 43.375L69.5532 47.0805"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
        <path
          d="M57.8628 43.1562L50.3952 47.4754"
          stroke="#0DDEBB"
          stroke-width="1.5"
        />
      </g>
      <rect
        y="0.749951"
        width="64.5743"
        height="64.5743"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 57.8741 8.375)"
        stroke="url(#paint0_linear_2479_494)"
        stroke-width="1.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2479_494"
          x1="-4.12937"
          y1="-3.87115"
          x2="69.8658"
          y2="-3.88081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0DDEBB" stop-opacity="0.25" />
          <stop offset="1" stop-color="#0DDEBB" />
        </linearGradient>
        <clipPath id="clip0_2479_494">
          <rect
            width="51.3302"
            height="51.3302"
            fill="white"
            transform="matrix(0.866041 0.499972 -0.866041 0.499972 57.4946 15.6953)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default inclineAI;
