type PropsType = {
  overrideStyle?: any;
};

const RedKey = ({ overrideStyle }: PropsType) => {
  return (
    <svg
      width="115"
      height="80"
      viewBox="0 0 115 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.749951"
        width="64.5743"
        height="64.5743"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 57.8731 7.375)"
        stroke="url(#paint0_linear_2479_496)"
        stroke-width="1.5"
      />
      <g clip-path="url(#clip0_2479_496)">
        <path
          d="M35.9172 45.5943L46.5051 51.7067C48.9729 53.1314 52.5473 53.7008 55.8819 53.2004L70.1886 51.0535C73.5232 50.5531 76.1114 49.0589 76.9781 47.1338L80.697 38.8745C81.5637 36.9494 80.5775 34.8859 78.1096 33.4611L67.5218 27.3487C65.0539 25.924 61.4795 25.3546 58.1449 25.855L43.8382 28.0019C40.5036 28.5023 37.9155 29.9965 37.0487 31.9216L33.3299 40.1809C32.4631 42.106 33.4493 44.1696 35.9172 45.5943Z"
          stroke="#FF90E7"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M48.5485 39.2015C50.7303 40.4611 54.2678 40.4611 56.4496 39.2015C58.6314 37.9419 58.6314 35.8997 56.4496 34.6401C54.2678 33.3805 50.7303 33.3805 48.5485 34.6401C46.3666 35.8997 46.3666 37.9419 48.5485 39.2015Z"
          stroke="#FF90E7"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M65.4791 44.4161L56.4492 39.2031"
          stroke="#FF90E7"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M60.3995 44.0924L62.6572 42.7891"
          stroke="#FF90E7"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2479_496"
          x1="-4.12937"
          y1="-3.87115"
          x2="69.8658"
          y2="-3.88081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF90E7" stop-opacity="0.25" />
          <stop offset="1" stop-color="#FF90E7" />
        </linearGradient>
        <clipPath id="clip0_2479_496">
          <rect
            width="49.5264"
            height="49.5264"
            fill="white"
            transform="matrix(0.866041 0.499972 -0.866041 0.499972 57.0137 14.7656)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default RedKey;
