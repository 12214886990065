import Navbar from "@desktop/Navbar";

/* Styles */
import {
  Wrapper,
  ContentWrapper,
  CardContainer,
} from "@styles/desktop/HomePage";
import {
  AboutHeaderSection,
  AboutHeaderBreadcrumb,
  BreadcrumbLine,
  BreadcrumbText,
  PersonCardTitle,
} from "@styles/desktop/ContactPage";

/* Components */
import BottomContent from "@desktop/BottomSection";
import PersonCard from "@common/PersonCard";
import ContactSection from "@common/ContactSection";
import { DavidImage, AkshaImage, VeithImage } from "@styles/desktop/Card";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <Wrapper>
        <AboutHeaderSection
          style={{
            background:
              "background: linear-gradient(95deg, rgba(131, 232, 255, 0.50) 0%, rgba(180, 255, 144, 0.50) 100%);",
          }}
        >
          <ContentWrapper>
            <AboutHeaderBreadcrumb>
              <BreadcrumbLine />
              <BreadcrumbText>Contact</BreadcrumbText>
            </AboutHeaderBreadcrumb>
          </ContentWrapper>
        </AboutHeaderSection>
        <ContentWrapper>
          <ContactSection />
        </ContentWrapper>
        <ContentWrapper>
          <PersonCardTitle>Contact the leadership team</PersonCardTitle>
        </ContentWrapper>
        <CardContainer>
          <PersonCard
            name="Akshat Gupta"
            title="CEO"
            email="akshat.g@berkeley.edu"
            profileUrl="https://www.linkedin.com/in/iamakshat/"
            image={<AkshaImage />}
          />
          <PersonCard
            name="Veith Weilnhammer"
            title="Chief Operating Officer"
            profileUrl="https://veithweilnhammer.github.io/"
            email="veith.weilnhammer@berkeley.edu"
            image={<VeithImage />}
          />
          <PersonCard
            name="David Whitney"
            title="Director of Cognitive Science UC Berkeley"
            email="dwhitney@berkeley.edu "
            profileUrl="https://vcresearch.berkeley.edu/faculty/david-whitney"
            image={<DavidImage />}
          />
        </CardContainer>
        <div style={{ height: "100px" }} />
        <BottomContent />
      </Wrapper>
    </>
  );
};

export default ContactPage;
