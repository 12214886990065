type PropsType = {
  overrideStyle?: any;
};

const inclineImage = ({ overrideStyle }: PropsType) => {
  return (
    <svg
      width="114"
      height="80"
      viewBox="0 0 114 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.749959"
        width="45.1376"
        height="45.1376"
        transform="matrix(0.866041 0.499972 -0.866041 0.499972 57.8673 7.375)"
        stroke="#9B8DFF"
        stroke-width="1.5"
      />
      <path
        d="M92.2226 36.5367L97.6079 39.6457L57.2178 62.9632L16.8276 39.6457L21.5398 36.9253"
        stroke="#9B8DFF"
        stroke-opacity="0.6"
        stroke-width="1.5"
      />
      <path
        d="M92.2226 46.6382L97.6079 49.7472L57.2178 73.0648L16.8276 49.7472L21.5398 47.0268"
        stroke="#9B8DFF"
        stroke-opacity="0.3"
        stroke-width="1.5"
      />
      <path
        d="M29.6177 27.6006L53.6562 26.3691C54.584 26.3236 55.3906 26.7893 55.3118 27.3249L53.1786 41.2025"
        stroke="#9B8DFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M59.4756 39.1165L60.7414 38.9979C61.6465 38.9465 62.4218 39.3941 62.3328 39.9166L61.2572 45.8612"
        stroke="#9B8DFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M75.3936 32.2596C73.5344 33.3329 70.5203 33.3329 68.6612 32.2596C66.8021 31.1864 66.8021 29.4463 68.6612 28.373C70.5203 27.2997 73.5344 27.2997 75.3935 28.373C77.2526 29.4463 77.2526 31.1864 75.3936 32.2596Z"
        stroke="#9B8DFF"
        stroke-width="1.5"
      />
    </svg>
  );
};
export default inclineImage;
