import styled from "styled-components";

export const VideoWrapper = styled.div`
  display: block;
  vertical-align: baseline;
  display: inline-block;
  object-fit: contain;
  overflow-clip-margin: content-box;
  overflow: clip;
`;

export const DetailSection = styled.div`
  cursor: pointer;
  border-radius: 24px 24px 0 0;
  padding-top: 30px;
  padding-bottom: 80px;
  margin-top: 40px;
  background-image: linear-gradient(280deg, #e3f8ff, #ece9ff);
`;

export const EssentialHeader = styled.h2`
  font-size: 24px;
  line-height: 1.4em;
  font-weight: 300;
  margin-bottom: 10px;
  letter-spacing: 1px;
  color: white;
  text-align: left;
  margin-top: 40px;
`;

export const EssentialSubheader = styled.div`
  font-size: 15px;
  line-height: 1.6em;
  font-weight: 100;
  margin-top: 30px;
  color: white;
  text-align: left;
`;

export const EssentialLearnMoreWrapper = styled.div`
  text-align: center;
  background-image: url(https://cdn.prod.website-files.com/666947d3f0c542911e8e494b/6669ea06108605cbb48e004d_sasha-bg-02.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
  letter-spacing: 1px;
  color: white;
  margin-top: 100px;
  padding: 40px 25px;
`;

export const EssentialLearnMoreHeader = styled.h1`
  font-size: 24px;
  line-height: 1.4em;
  font-weight: 400;
`;

export const EssentialLearnMoreBody = styled.p`
  font-size: 14px;
  line-height: 1.5em;
  margin-bottom: 60px;
  font-weight: 200;
`;
