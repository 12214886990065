import styled from "styled-components";

export const GetInTouchTextContentWrapper = styled.div`
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
`;

export const GetInTouchHeader = styled.h1`
  font-size: 24px;
  line-height: 1.4em;
  font-weight: 400;
  text-align: left;
`;

export const GetInTouchSubHeader = styled.p`
  font-size: 14px;
  line-height: 1.5em;
  margin-bottom: 10px;
  text-align: left;
  letter-spacing: 0.5px;
  font-weight: 200;
`;

export const GetInTouchImageWrapper = styled.div`
  width: 50%;
`;

export const GetInTouchImage = styled.img``;
