import { LongContentCardFooterWrapper } from "@styles/desktop/Card";

import ArrowCircle from "@assets/icons/ArrowCircle";

type PropsType = {
  text: string;
  overrideTextStyle?: any;
  onArrowClick?: () => any;
};

const ArrowButtonText = ({
  text,
  overrideTextStyle,
  onArrowClick,
}: PropsType) => {
  return (
    <LongContentCardFooterWrapper
      style={{ ...overrideTextStyle }}
      onClick={onArrowClick}
    >
      <ArrowCircle overrideStyle={{ marginRight: "12px" }} />
      {text}
    </LongContentCardFooterWrapper>
  );
};

export default ArrowButtonText;
