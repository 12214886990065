import styled from "styled-components";
import doctor from "@assets/images/doctor.png";

export const Wrapper = styled.div`
  width: 100%;
  background-color: #111224;
  padding-bottom: 2vh;
`;

export const Background = styled.div`
  z-index: 10;
  width: 100%;
  min-height: 95vh;
  position: absolute;
  inset: 0%;
  background: url(${doctor}) lightgray 50% / cover no-repeat;
`;

export const HelloSection = styled.div`
  z-index: 20;
  background-image: linear-gradient(0deg, #111224, #7b68ff00);
  justify-content: center;
  align-items: center;
  min-height: 95vh;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  position: relative;
  color: white;
`;

export const ContentWrapper = styled.div`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  width: 85%;
  text-align: center;
`;

export const TitleWrapper = styled.div`
  color: #fff;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 1px;
`;

export const Subtitle = styled.div`
  font-size: 17px;
  line-height: 1.5em;
  font-weight: 300;
  margin-bottom: 10px;
`;

export const StatementWrapper = styled.div`
  font-size: 28px;
  letter-spacing: 1.5px;
  color: white;
`;

export const Divider = styled.div`
  width: 80%;
  margin: 20px 10vw;
  border: 1px solid #6e6b5d;
  margin-top: 30px;
`;

export const Body = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height */
  padding: 18px;
  display: flex;
  text-align: center;
  color: #eee8e6;
`;

export const InfoBody = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  padding-bottom: 4px;
  margin-left: 8px;
  color: white;
`;

export const FooterTextTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 4px;
  color: white;
  text-align: left;
  margin-top: 20px;
`;

export const FooterText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  padding-bottom: 4px;
  color: white;
  text-align: left;
  opacity: 0.7;
`;

export const CounterText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;

export const DetailSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px 24px 0 0;
  background: linear-gradient(275deg, #e3f8ff 0%, #9fc7d3 100%);
`;

export const DetailSectionHeader = styled.div`
  text-align: left;
  color: #111224;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  width: 80%;
`;

export const DetailSectionSubHeader = styled.div`
  color: #111224;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  margin-top: 12px;
  text-align: left;
`;

export const StorySection = styled.section`
  display: flex;
  justify-content: center;
  padding: 30px 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px 24px 0 0;
  background: linear-gradient(
    66deg,
    rgba(54, 171, 164, 0.15) 0%,
    rgba(78, 117, 200, 0.5) 100%
  );
  margin-top: 8vh;
`;

export const StorySectionHeader = styled.div`
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4em;
  text-align: left;
  margin-top: 20px;
`;

export const StorySectionSubHeader = styled.div`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4em;
  text-align: left;
  letter-spacing: 1px;
  margin-top: 10px;
`;

export const StorySectionBody = styled.div`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: 1.5em;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
  opacity: 0.7;
`;
