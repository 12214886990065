import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ABOUT_PAGE } from "../../Routes";
import Dot from "@assets/icons/Dot";
import {
  ImageCardWrapper,
  ImageCardPrefix,
  ImageCardTitle,
  ImageCardContentWrapper,
  ImageCardBody,
  ImageWrapper,
  ImageWrapperSmall,
  ImageCardTextWrapper,
  ImageCardTextTitleWrapper,
  ImageCardTextBodyWrapper,
} from "@styles/desktop/Card";

import SquareImage from "@assets/icons/SquareImage";
import OrangeWarn from "@assets/icons/OrangeWarn";
import GreenScan from "@assets/icons/GreenScan";

export const ICON_TYPE = {
  SQUARE: 1,
  WARN: 2,
  SCAN: 3,
};

type PropsType = {
  prefix?: string;
  title: string;
  body: string;
  image?: React.ReactNode;
  isMobile?: boolean;
  iconType: number;
};

const ICON_MAPS = {
  [ICON_TYPE.SQUARE]: {
    component: <SquareImage />,
    titleColor: "#7b68ff",
  },
  [ICON_TYPE.SCAN]: {
    component: <GreenScan />,
    titleColor: "#01bcc8",
  },
  [ICON_TYPE.WARN]: {
    component: <OrangeWarn />,
    titleColor: "#ff6332",
  },
};

const IconCard = ({
  prefix,
  title,
  body,
  image,
  isMobile,
  iconType,
}: PropsType) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);

  // Function to check if the DiamondCard is in the viewport
  const handleScroll = () => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const iconImage = ICON_MAPS[iconType].component;
  const titleColor = ICON_MAPS[iconType].titleColor;

  return (
    <ImageCardWrapper ref={cardRef} isMobile={isMobile} isVisible={isVisible}>
      <ImageWrapper>{iconImage}</ImageWrapper>
      <ImageCardContentWrapper backgroundColor={titleColor}>
        <ImageCardPrefix isMobile={isMobile} color={titleColor}>
          {prefix}
        </ImageCardPrefix>
        <ImageCardTitle isMobile={isMobile} color={titleColor}>
          {title}
        </ImageCardTitle>
        <ImageCardBody>{body}</ImageCardBody>
      </ImageCardContentWrapper>
    </ImageCardWrapper>
  );
};

export default IconCard;
