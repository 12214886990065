import { connect } from "react-redux";

import { getShouldRenderMobileComponent } from "@data/selectors/BrowserSelectors";

import DesktopTechPage from "@desktop/TechPage";
import MobileTechPage from "@mobile/TechPage";

const mapStateToProps = (state: any) => ({
  shouldRenderMobileComponent: getShouldRenderMobileComponent(state),
});

type PropsType = ReturnType<typeof mapStateToProps>;

const TechPageSwitch = ({ shouldRenderMobileComponent }: PropsType) => {
  return shouldRenderMobileComponent ? <MobileTechPage /> : <DesktopTechPage />;
};

export default connect(mapStateToProps)(TechPageSwitch);
