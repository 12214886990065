type PropsType = {
  overrideStyle?: any;
};

const OrangeWarn = ({ overrideStyle }: PropsType) => {
  return (
    <svg
      width="320"
      height="190"
      viewBox="0 0 320 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.749951"
        width="161.796"
        height="161.796"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 160.749 2.375)"
        stroke="#FF4E02"
        strokeWidth="1.5"
      />
      <path
        d="M290.207 97.5252L301.521 104.057L160.1 185.699L18.6783 104.057L29.992 97.5252"
        stroke="#FF4E02"
        strokeOpacity="0.5"
        strokeWidth="1.5"
      />
      <circle
        cx="51.8156"
        cy="51.8156"
        r="51.3156"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 160.1 31.8359)"
        fill="url(#paint0_radial_2477_492)"
        stroke="white"
      />
      <circle
        cx="32.7635"
        cy="32.7635"
        r="32.7635"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 160.1 50.8828)"
        fill="#FF4E02"
        fillOpacity="0.3"
      />
      <circle
        cx="26.1273"
        cy="26.1273"
        r="25.6273"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 160.1 57.5156)"
        stroke="white"
      />
      <path
        d="M169.241 77.4746C170.608 77.2633 171.858 77.985 171.492 78.7736L164.946 92.8758C164.58 93.6644 162.873 93.9285 161.873 93.3512L143.99 83.0277C142.99 82.4504 143.448 81.4646 144.814 81.2533L169.241 77.4746Z"
        stroke="white"
      />
      <line
        y1="-0.5"
        x2="8.16479"
        y2="-0.5"
        transform="matrix(-0.866044 0.499967 -0.866044 -0.499967 165.757 80.375)"
        stroke="white"
      />
      <circle
        cx="1.22472"
        cy="1.22472"
        r="0.724718"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 156.564 84.8672)"
        fill="white"
        stroke="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2477_492"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(51.8156 51.8156) rotate(90) scale(51.8156)"
        >
          <stop stopColor="#FF4E02" />
          <stop offset="1" stop-color="#FF4E02" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};
export default OrangeWarn;
