import Navbar from "@mobile/Navbar";

/* Styles */
import { Wrapper, ContentWrapper } from "@styles/mobile/HomePage";
import {
  AboutHeaderSection,
  AboutHeaderBreadcrumb,
  BreadcrumbLine,
  BreadcrumbText,
  PeopleSectionHeader,
} from "@styles/mobile/AboutPage";
import { ImageWrapper } from "@styles/mobile/ContactPage";

/* Components */
import PersonCard from "@common/PersonCard";
import ContactForm from "@common/ContactSectionMobile";
import Footer from "@mobile/Footer";

import { DavidImage, AkshaImage, VeithImage } from "@styles/desktop/Card";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <Wrapper>
        <AboutHeaderSection>
          <ContentWrapper>
            <AboutHeaderBreadcrumb>
              <BreadcrumbLine />
              <BreadcrumbText>Contact</BreadcrumbText>
            </AboutHeaderBreadcrumb>
          </ContentWrapper>
        </AboutHeaderSection>
        {/* <ImageWrapper>
          <img
            src="https://cdn.prod.website-files.com/666947d3f0c542911e8e494b/6669ed0b9d2bb61c7535460d_sasha-office.png"
            style={{ maxWidth: "85vw" }}
          />
        </ImageWrapper> */}
        <ContactForm />

        <ContentWrapper>
          <PeopleSectionHeader style={{ color: "white" }}>
            Meet the leadership team
          </PeopleSectionHeader>
          <div style={{ height: "40px" }} />
          <PersonCard
            name="David Whitney"
            title="Director of Cognitive Science UC Berkeley"
            email="dwhitney@berkeley.edu "
            profileUrl="https://vcresearch.berkeley.edu/faculty/david-whitney"
            isMobile
            image={<DavidImage />}
          />
          <div style={{ height: "30px" }} />
          <PersonCard
            name="Akshat Gupta"
            title="CEO"
            email="akshat.g@berkeley.edu"
            profileUrl="https://www.linkedin.com/in/iamakshat/"
            isMobile
            image={<AkshaImage />}
          />
          <div style={{ height: "30px" }} />
          <PersonCard
            name="Veith Weilnhammer"
            title="Chief Operating Officer"
            profileUrl="https://veithweilnhammer.github.io/"
            email="veith.weilnhammer@berkeley.edu"
            isMobile
            image={<VeithImage />}
          />
        </ContentWrapper>
        <Footer />
      </Wrapper>
    </>
  );
};

export default ContactPage;
