import { useState, useEffect, useRef } from "react";
import Dot from "@assets/icons/Dot";
import {
  ImageCardWrapper,
  ImageCardTitle,
  ImageCardBodyList,
  ImageCardBodyListItem,
  ImageWrapper,
} from "@styles/desktop/Card";

type PropsType = {
  prefix: string;
  title: string;
  body: Array<String>;
  image?: any;
  isMobile?: boolean;
};

const ImageCard = ({
  prefix,
  title,
  body,
  image,
  isMobile = false,
}: PropsType) => {
  const margin = isMobile ? "8px 8px 0 0" : "10px 8px 0 0";
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);

  // Function to check if the DiamondCard is in the viewport
  const handleScroll = () => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <ImageCardWrapper ref={cardRef} isMobile={isMobile} isVisible={isVisible}>
      {/* <ImageCardPrefix isMobile={isMobile}>{prefix}</ImageCardPrefix> */}
      <ImageCardTitle isMobile={isMobile}>{title}</ImageCardTitle>
      <ImageWrapper>{image}</ImageWrapper>
      <ImageCardBodyList>
        {body.map((item, index) => (
          <ImageCardBodyListItem key={index} isMobile={isMobile}>
            <Dot overrideStyle={{ margin }} />
            <p>{item}</p>
          </ImageCardBodyListItem>
        ))}
      </ImageCardBodyList>
    </ImageCardWrapper>
  );
};

export default ImageCard;
