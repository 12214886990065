import { useState, useRef, useEffect } from "react";
import {
  ImageWrapperSmall,
  ImageCardTextWrapper,
  ImageCardTextTitleWrapper,
  ImageCardTextBodyWrapper,
} from "@styles/desktop/Card";

import SquareImage from "@assets/icons/SquareImage";

type PropsType = {
  title: string;
  body: string;
  image?: any;
  isMobile?: boolean;
};

const IconCardSmall = ({ title, body, image, isMobile }: PropsType) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);

  // Function to check if the DiamondCard is in the viewport
  const handleScroll = () => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <ImageWrapperSmall ref={cardRef} isVisible={isVisible} isMobile={isMobile}>
      {image}
      <ImageCardTextWrapper>
        <ImageCardTextTitleWrapper isMobile={isMobile}>
          {title}
        </ImageCardTextTitleWrapper>
        <ImageCardTextBodyWrapper isMobile={isMobile}>
          {body}
        </ImageCardTextBodyWrapper>
      </ImageCardTextWrapper>
    </ImageWrapperSmall>
  );
};

export default IconCardSmall;
