import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HOME_PAGE } from "../../Routes";

/* Components */
import Dropdown from "@mobile/DropdownPanel";
import Button, { BUTTON_TYPE } from "@common/Button";

/* Styles */
import {
  Wrapper,
  MainContent,
  LogoContainer,
  LogoImg,
} from "@styles/mobile/Navbar";

const Navbar = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [shouldChangeHeight, setShouldChangeHeight] = useState(false); // [TODO
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, [shouldChangeHeight]);

  return (
    <>
      <Wrapper
        ref={ref}
        initialHeight={height}
        showDropdown={isDropdownVisible}
        onMouseLeave={() => setIsDropdownVisible(false)}
      >
        <MainContent>
          <div style={{ display: "flex", marginTop: "4px" }}>
            <LogoContainer onClick={() => navigate(HOME_PAGE)}>
              <LogoImg />
            </LogoContainer>
          </div>
          <Button
            variant={BUTTON_TYPE.GHOST}
            overrideStyle={{
              color: "white",
              borderRadius: "60px",
              border: "1px solid rgba(1, 188, 200, 0.50",
              padding: "4px 8px",
              marginTop: "4px",
              fontWeight: 300,
              fontSize: "12px",
              marginBottom: "4px",
            }}
            onClick={() => setIsDropdownVisible(!isDropdownVisible)}
          >
            Menu
          </Button>
        </MainContent>
        {isDropdownVisible && (
          <Dropdown setShouldChangeHeight={setShouldChangeHeight} />
        )}
      </Wrapper>
    </>
  );
};

export default Navbar;
